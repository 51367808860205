.container {
    align-items: center;
    background-color: #F5FBFB;
    max-width: 100%;
    min-height: 100vh;
}

div.login-box-wrapper {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px;
    max-width: 479px;
    margin: 100px auto;
}

.login-btn {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    border: 1px solid #474747;
    background-color: transparent;
    padding: 15px;
    border-radius: 8px;
    font-size: 18px;
    line-height: 20px;
    font-family: 'FuturaBT-Book', sans-serif;
    font-weight: 500;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
}

.login-btn.mobile {
    background-color: #69be4e;
    color: #ffffff;
    border: 1px solid #69be4e;
    margin: 20px auto;
}

.login-btn img {
    width: 22px;
    height: auto
}
.login-btn.mobile img {
    width: 18px;
}

.login-btn span {
    padding-left: 10px;
    text-align: center;
    width: 75%;
}

.orTextStyle {
    color: #000000;
    font-weight: 600;
    align-self: center;
    margin: 20px;
}

.signUpButtonStyle {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-width: 0;
    border-radius: 0;
    background-color: #ffffff;
    padding: 11px;
    height: 100%;
    width: 100%;
}

.signUpButtonTextStyle {
    color: "#2553b4";
    font-size: 11px;
    font-family: "Futura";
}

.titleWhySignUp {
    margin-bottom: 32PX;
    width: 700px;
    height: 56px;
    font-family: "Futura";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #6D6D6D;
}

.labelTitle {
    font-family: "FuturaBT",sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 25px;
    text-align: center;
    margin-top: 24px;
    color: #474747;
    margin-bottom: 30px;
}

.registerNowText {
    font-family: "FuturaBT-Book",sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #00BC4A;
    cursor: pointer;
}

.didNotRegisterText {
    font-family: "FuturaBT-Book",sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #474747;
    margin-top: 10px;
}

.mainHeader {
    padding: 15;
    display: "flex";
    width: "100%";
    justify-content: "space-between";
}

.rightNav {
    display: "flex";
    align-items: "center";
    text-align: "center";
}

.rightNav.MuiTypography-root {
    padding: "0 15px";
    color: "#353535";
    opacity: "0.79";
    font-family: "Futura Bk BT; Futura";
    font-weight: "400";
}

.rightNav.MuiTypography-root:hover {
    text-decoration: none;
}

.registerButtonStyle {
    color: "#00BC4A";
    border: "1px solid #00BC4A";
    font-family: "Futura Bk BT; Futura";
    padding: "5px 10px";
    text-transform: "capitalize";
}

.registerButtonStyle:hover {
    background-color: "#00BC4A";
    color: "#fff";
}

.registerButtonStyle.MuiButton-label {
    font-size: 13px;
    font-weight: 600;
}


@media only screen and (max-width: 479px) {
    .login-btn {
        font-size: 14px;
    }
}

@media only screen and (max-width: 360px) {
    .login-btn span{
        width: calc(100% - 22px);
    }
}