.bloglist .MuiCardMedia-root.MuiCardMedia-media.MuiCardMedia-img {
    box-shadow: 0px 0px 10px 2px #e1e1e18a !important;
    border-radius: 5px !important;
}

.bloglist .MuiCardActions-root.MuiCardActions-spacing h5 {
    margin: 0px !important;
    margin-top: 20px !important;
    text-transform: capitalize;
    font-weight: 500;
}

.bloglist .MuiCardActions-root.MuiCardActions-spacing h5:nth-child(1) {

    margin-right: 15px !important;
}

.bloglist .MuiCardContent-root {
    padding: 10px 0px 15px 3px !important;
}

.bloglist .MuiCardContent-root .MuiTypography-root.MuiTypography-h5.MuiTypography-gutterBottom {
    font-size: 23px !important;
    font-family: 'FuturaBT-Medium', sans-serif;
    font-weight: 500;
}

.bloglist .MuiCardActions-root.MuiCardActions-spacing button {
    font-size: 14px;
    text-transform: capitalize;
    color: #212121;
    font-weight: 600;
    text-decoration: underline !important;
    font-family: 'FuturaBT-Medium',sans-serif;
    letter-spacing: 1;
}
.blog-side-list-title.MuiTypography-body2 {
    font-family: 'FuturaBT-book',sans-serif;
}
.blog-discription.MuiTypography-root {
    font-family: 'FuturaBT-book',sans-serif;
}

.bloglist .MuiCardActions-root.MuiCardActions-spacing {
    padding: 0px !important;
}

.bloglist .MuiPaper-root.MuiCard-root.MuiPaper-elevation1.MuiPaper-rounded {
    box-shadow: none !important;
    margin-bottom: 40px;
}

.blogsidelist img {
    margin-right: 25px;
}

.bloglist .MuiGrid-spacing-xs-3>.MuiGrid-item {

    padding: 12px 25px !important;
}

.bloglist .MuiGrid-spacing-xs-2>.MuiGrid-item {

    padding: 8px 25px;
}


.bloglist h3 {
    font-size: 28px;
    padding-left: 15px;
    color: #000000c7;
    font-family: 'FuturaBT-Book',sans-serif;
    letter-spacing: 1;
}

.bloglist h2 {
    font-size: 30px;
    text-align: center;
    color: #000000c7;
    font-family: 'FuturaBT-Book',sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'FuturaBT-Book', sans-serif;
}

.blogdetailshead {
    text-align: center;
}

.blogdetailshead h3 {
    text-align: center;
    font-size: 24px;
    text-decoration: underline;
    color: #000000c9;
    font-family: 'Futura-light',sans-serif;
}

.blogdetailshead h2 {
    font-size: 34px;
    font-weight: 500;
    font-family: 'FuturaBT-Book',sans-serif;
}

.blogdetails h4 {
    font-size: 24px;
    font-weight: 500;
}

.blogdetails p {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
    font-family: 'FuturaBT-Book', sans-serif;
}

.blogsidelist .MuiCardMedia-media {
    width: 300px !important;
}

hr {
    border: none;
    border-top: 2px dotted rgba(174, 174, 174, 0.98);
    color: #fff;
}

.blogdetailshead img {
    width: 100%;
    max-height: 650px;
    object-fit: cover;
}

.blogsidelistcard {
    display: flex;
}

.blogdetails .editor tt {
    font-family: 'FuturaBT-Book',sans-serif;
    font-size: 20px;
    line-height: 26px;
}

.loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height:100%;
}
.secimg {
    width:620px ;
     height:470px;
}
@media only screen and (max-width: 700px) {
    .bloglist h3 {
        font-size: 18px;
    }

    .blogsidelistcard {

        flex-direction: column;
    }
    .blogsidelist .MuiCardMedia-media {
        width: 100% !important;
    }
    .secimg {
     
         height:220px;
    }
}