.container {
    align-items: center;
    background-color: #F5FBFB;
    max-width: 100%;
}

.page-sub-title {
    margin-bottom: 32px;
    font-family: "FuturaBT-Book", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #6D6D6D;
    max-width: 100%;
}

.page-title {
    font-family: "FuturaBT-Book", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 32px;
    color: #474747;
    display: inline-block;
}

.mobileNumberContainer {
    width: 534px;
    background: "#FFFFFF";
    border: 1px solid #474747;
    border-radius: 4px;
    padding: 30;
}

.questionTextStyle {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-family: "FuturaBT-Book", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #474747;
    max-width: 100%;
}

.otpTextStyle {
    font-family: "FuturaBT-Book", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #414141;
    margin-bottom: 9px;
    margin-top: 30px;
}

.questionMainContainer {
    width: 534px;
    display: flex;
    flex-direction: column;
    background: "#FFFFFF";
    border: 1px solid #474747;
    border-radius: 4px;
    padding: 29px;
    max-width: 100%;
}

.sendOtpButtonStyle {
    height: 56px;
    background-color: #00BC4A;
    border-radius: 4px;
    text-transform: none;
    font-family: "sans-serif";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}

.container-inner {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #F5FBFB;
    margin: 0px;
    box-sizing: border-box !important;
}

.radio-btn-group {
    margin-bottom: 40px;
    margin-top: 20px;
    margin-left: 24px;
    font-size: 18px;
    font-family: "FuturaBT-Book", sans-serif;
}

.radio-fc {
    font-size: 18px;
    color: #4A4A4A;
    font-family: "FuturaBT-Book", sans-serif;
    font-weight: 400;
}

.radio-fc .MuiFormControlLabel-label {
    font-family: 'FuturaBT-Book',sans-serif;
    font-weight: normal;
}

.question-index {
    max-width: 24px;
    width: 100%;
}

.question-text {
    max-width: calc(100% - 24px);
}

button.confirm-buttton {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00BC4A;
    border-radius: 4px;
    text-transform: none;
    font-family: "FuturaBT-Book", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    margin-top: -13px;
    cursor: pointer;
    letter-spacing: 1.8;
    color: #ffffff !important;
}

button.confirm-buttton:hover {
    background-color: #00BC4A;
}

button.confirm-buttton.Mui-disabled {
    background-color: rgba(0, 188, 74, .7);
    cursor: not-allowed;
}

.validation-popup {
    max-width: 479px;
    width: 100%;
    padding: 40px;
    border-radius: 12px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
}

.popup-title {
    font-family: "FuturaBT-Book", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #474747;
    margin: 20px 0;
    display: inline-block;
}

.model-discription {
    font-family: "FuturaBT-Book", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #6D6D6D;
}

.pop-up-action-btn {
    height: 56px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00BC4A;
    border-radius: 4px;
    text-transform: none;
    font-family: "FuturaBT-Book", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    margin-top: 25px;
    color: #FFFFFF;
    cursor: pointer;
    letter-spacing: 1.8;
}

@media only screen and (max-width: 767px) {
    .validation-popup {
        padding: 25px;
    }
}

@media only screen and (max-width: 480px) {
    .questionTextStyle {
        font-size: 15px;
    }
    .page-title {
        font-size: 24px;
        line-height: 26px;
    }
    .page-sub-title {
        font-size: 14px;
        line-height: 16px;
    }
    .questionMainContainer {
        padding: 20px;
    }
    .validation-popup {
        max-width: 95%;
    }
}