.sendOtpContainer {
    align-items: center;
    background-color: #F5FBFB;
    max-width: 100%;
}

.inner-container.MuiContainer-root {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #F5FBFB;
    margin: 100px auto;
}

.form-box {
    max-width: 534px;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #474747;
    border-radius: 4px;
    padding: 30px;
    width: 100%;
    box-sizing: border-box;
}

.titleWhySignUp {
    margin-bottom: 32px;
    width: 700px;
    height: 56px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #6D6D6D;
}

.labelTitle {
    font-family: "FuturaBT-Book", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 25px;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 32px;
    color: #474747;
}

.mobileNumberContainer {
    width: 534px;
    margin: 100px auto;
    background: #FFFFFF;
    border: 1px solid #474747;
    border-radius: 4px;
    padding: 30;
}

.mobileNumberTextStyle {
    font-family: "FuturaBT-Medium", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: #6e6e6e;
    margin-bottom: 9;
}
.mobile-no-fc.MuiFormControl-root .Mui-disabled {
    background-color: #777171;
}
.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input.Mui-disabled {
    color: #777171;
}
.otpTextStyle {
    font-family: "FuturaBT-Medium", sans-serif !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 17px;
    color: #6e6e6e !important;
    margin-bottom: 9px;
    margin-top: 30px;
}

.sendOtpButtonStyle {
    height: 56px;
    background-color: #00BC4A;
    border-radius: 4px;
    text-transform: none;
    font-family: "FuturaBT-Book", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}

.mobileno-fieldset.MuiContainer-root {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0;
}

.country-code-fc.MuiFormControl-root {
    min-width: 80px;
    height: 56px;
    border: 1px solid #CDD8D8;
    border-radius: 4px;
    margin-right: 9px;
    padding: 0;
}

.country-code-select {
    height: 100%;
    width: 100%;
    padding: 5px;
    pointer-events: none;
}

.country-code-select .MuiSelect-selectMenu {
    padding: 5px !important;
    text-align: center;
    box-sizing: border-box;
}

.country-code-select svg {
    display: none;
}

.mobile-no-fc.MuiFormControl-root {
    width: calc(100% - 90px);
    min-height: 56px;
    height: 100%;
    padding: 0;
}
.mobile-no-fc.MuiFormControl-root input {
    height: 56px;
    box-sizing: border-box;
}
.mobile-no-fc.MuiFormControl-root .Mui-disabled {
    background-color: #f1f5f6;
}

.validation-msg {
    font-family: "FuturaBT-Book", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0;
    color: #FF3B30;
    margin-top: 8px;
}

.send-otp-btn {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00BC4A;
    border-radius: 4px;
    text-transform: none;
    font-family: "FuturaBT-Book", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #FFFFFF;
    margin-top: 30px;
    cursor: pointer;
    max-width: 100%;
    letter-spacing: 1.8;
}

.input-addon {
    font-family: "FuturaBT-Book", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: "#939393";
    margin-right: 68px;
}

div.otp-container.MuiContainer-root {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    padding: 0;
}

.otp-container .opt-field-fc {
    width: calc(100% / 6 - 10px);
    height: 56px;
    margin: 0 5px;
}

.otp-container .opt-field-fc:first-child {
    margin-left: 0;
}

.otp-container .opt-field-fc:last-child {
    margin-right: 0;
}

.opt-field-fc .MuiOutlinedInput-root {
    height: 100%;
    justify-content: center;
}

.opt-field-fc .Mui-focused {
    border-color: #CDD8D8;
}

.otp-container .opt-field-fc input {
    padding: 5px !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 56px;
    height: 100%;
    box-sizing: border-box;
}

.confirm-otp-btn {
    height: 56;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00BC4A;
    border-radius: 4px;
    text-transform: none;
    font-family: "FuturaBT-Book", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #FFFFFF;
    margin-top: 29px;
    cursor: pointer;
    letter-spacing: 1.8;
}

.otp-additional-info-container.MuiContainer-root {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0;
}

.otpNotRecieved-msg {
    font-size: 14px;
    font-family: "FuturaBT-Book", sans-serif;
    color: #6c6c6c;
    font-style: normal;
    font-weight: 400;
}

.resend-otp-link {
    font-family: "FuturaBT-Book", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #00BC4A;
    margin-top: 7px;
}

.txt-right {
    text-align: right;
}

@media only screen and (max-width: 479px) {
    .form-box {
        padding: 15px;
    }
    .labelTitle {
        font-size: 24px;
    }
    .inner-container.MuiContainer-root {
        margin: 40px auto;
    }
}