.slides-section {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
}

.slides-section .slides-inner-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    margin: 20px 0;
}

.section-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;
    width: calc(100% / 4 - 30px);
    box-sizing: border-box;
    cursor: pointer;
}

.section-card:first-child {
    margin-left: 0;
}

.section-card:last-child {
    margin-right: 0;
}

.section-card .card-img-wrapper {
    max-height: 400px;
    min-height: 400px;
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s;
}

.section-card .card-img-wrapper > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.section-card .card-img-wrapper .hover-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    font-family: "FuturaBT-Book", sans-serif;
    font-size: 18px;
    line-height: 26px;
    transform: scale(0);
    transition: 0.4s;
    opacity: 0;
    z-index: 99;
}

.section-card .people-name {
    font-size: 22px;
    line-height: 26px;
    font-family: 'FuturaBT-Medium',sans-serif;
    font-weight: 600;
    margin: 10px 0;
    letter-spacing: .5px;
    display: inline-block;
    width: 100%;
    text-align: center;
}

.section-card .people-designation{
    font-size: 18px;
    line-height: 22px;
    font-family: 'FuturaBT-Book',sans-serif;
    font-weight: 600;
    letter-spacing: 0.5px;
    display: inline-block;
    width: 100%;
    text-align: center;
}

.slides-section h2.heading {
    font-family: 'FuturaBT-Medium',sans-serif !important;
    font-weight: 600 !important;
}

.campaignTabTitle {
    letter-spacing: 1px !important;
}

.list-scrollView {
    max-height: 942px;
    overflow-y: auto;
}
.list-scrollView::-webkit-scrollbar {
    width: 5px;
}
.list-scrollView::-webkit-scrollbar-thumb {
    width: 5px;
    background: #dedede;
    border-radius: 5px;
}

@media only screen and (max-width: 1279px) {
    .list-scrollView {
        max-height: 1063px;
    }
}

@media only screen and (max-width: 767px) {
    .slides-section .slides-inner-container {
        flex-wrap: wrap;
        justify-content: center;
    }
    .section-card {
        max-width: 350px;
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
    }
    .section-card:last-child {
        margin: 0;
    }
}

@media only screen and (max-width: 599px) {
    .list-scrollView {
        max-height: 1140px;
    }
}

@media only screen and (max-width: 414px) {
    .list-scrollView {
        max-height: 1282px;
    }
}