.terms-wrapper {
    max-width: 1280px;
    margin: 50px auto;
    padding: 0 20px;
    box-sizing: border-box;
}

.terms-wrapper .page-title {
    text-align: center;
    width: 100%;
    color: #000;
}

.terms-wrapper>p {
    font-size: 18px;
    line-height: 24px;
    display: inline-block;
    width: 100%;
    margin: 0;
    margin-bottom: 25px;
    text-align: justify;
}

.terms-wrapper li {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
    text-align: justify ;
}

.terms-wrapper li > span:first-child {
    text-align: left;
    display: inline-block;
}

.terms-wrapper li:last-child {
    margin-bottom: 0;
}

.terms-wrapper li.list-item {
    margin-bottom: 25px;
}

.terms-wrapper li.list-item:last-child {
    margin-bottom: 0;
}

.terms-wrapper .list-item .head {
    /* font-weight: 600; */
    font-family: 'FuturaBT-Medium', sans-serif;
}

.terms-wrapper .sub-list.alphbatic {
    list-style-type: none;
    counter-reset: item;
    padding-left: 0;
    padding-left: 40px;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    margin-top: 20px;
}

.sub-list.alphbatic > span::before,
.sub-list.alphbatic > li::before {
    content: "(" counter(item, lower-alpha) ")";
    counter-increment: item;
    font-family: 'FuturaBT-Medium',sans-serif;
    position: absolute;
    left: 8px;
}

.sub-list.alphbatic > span {
    margin-bottom: 8px;
    display: inline-block;
}

.sub-list.alphbatic > span:last-child {
    margin-bottom: 0;
}

.terms-wrapper .sub-list.dist {
    list-style-type: disc;
}

.terms-wrapper ol li::marker {
    font-family: 'FuturaBT-Medium',sans-serif;
}