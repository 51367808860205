.policy-wrapper {
    max-width: 1280px;
    margin: 50px auto;
    padding: 0 20px;
    box-sizing: border-box;
}

.policy-wrapper .page-title {
    text-align: center;
    width: 100%;
    color: #000;
}

.policy-wrapper p {
    font-size: 18px;
    line-height: 24px;
    display: inline-block;
    width: 100%;
    margin: 0;
    margin-bottom: 25px;
    text-align: justify;
}
.policy-wrapper p:last-child{
    margin-bottom: 0;
}

.policy-page .m-0 {
    margin: 0;
}

.policy-wrapper .policy-topic {
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
}

.policy-topic .topic-head {
    display: inline-block;
    width: 100%;
    font-size: 20px;
    line-height: 24px;
    font-family: 'FuturaBT-Medium',sans-serif;
    margin-bottom: 15px;
    font-weight: 600;
    letter-spacing: .3px;
    color: #212121;
}

.policy-topic .inner-list li {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
}

.policy-topic .inner-list li:last-child {
    margin-bottom: 0;
}


@media only screen and (max-width: 767px) {
    .policy-wrapper p,
    .policy-topic .inner-list li {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 15px;
    }
    .policy-topic .topic-head {
        font-size: 18px;
        line-height: 22px;
    }
    .policy-topic p span {
        display: inline-block;
        text-align: left;
        margin: 3px 0;
    }
}