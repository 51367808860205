.Dialog {
    width: 100%;
}

.Dialog .MuiDialog-paper {
    position: relative;
    max-width: 400px;
    box-sizing: border-box;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 12px;
}

.Dialog .dialog-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.Dialog .dialog-inner .icon-img {
    min-height: 80px;
    min-width: 80px;
}

.Dialog .dialog-inner>* {
    margin: auto;
    margin-bottom: 15px;
}

.Dialog .dialog-inner .dialog-title {
    font-size: 20px;
    line-height: 22px;
    font-family: 'FuturaBT-Medium', sans-serif;
    font-weight: 600;
    letter-spacing: 1;
    text-align: center;
}

.Dialog .dialog-inner .dialog-dis {
    font-size: 16px;
    line-height: 18px;
    font-family: 'FuturaBT-Book', sans-serif;
    font-weight: normal;
    text-align: center;
}

.Dialog .dialog-actions {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    width: 100%;
    margin-top: 10px;
}

.Dialog .dialog-actions button {
    padding: 12px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 600;
    font-family: 'FuturaBT-Medium', sans-serif;
    width: 100%;
    background-color: #68be5a;
    color: #ffffff;
    text-transform: none;
    margin-bottom: 12px;
}

.Dialog .dialog-actions button:last-child {
    margin-bottom: 0;
}

.Dialog.Ask-Ekyc .MuiDialog-paper {
    padding-left: 40px;
    padding-right: 40px;
}

.Dialog.Ask-signup .dialog-inner .dialog-title {
    line-height: 26px;
}

.Dialog .dialog-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    min-width: auto;
    cursor: pointer;
}
.Dialog .dialog-inner .icon-img {
    min-height: 80px;
    min-width: 80px;
}