.profile-container-main {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: 100%;
}

.profile-bg-main {
    background: #f5fbfb;
    width: 100%;
    min-height: 100vh;
    border-top-left-radius: 18px;
    box-sizing: border-box;
    padding: 40px 160px 25px 70px;
}

.profile-bg-main .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.profile-header .profile-title {
    font-size: 26px;
    line-height: 28px;
    font-weight: 500;
    font-family: sans-serif;
    letter-spacing: .2px;
    color: #464849;
    display: flex;
    align-items: center;
}

.data-label {
    color: #a7a7a7;
    text-transform: capitalize;
    margin-bottom: 5px;
    display: inline-block;
    font-size: 16px;
    line-height: 18px;
    font-family: 'FuturaBT-Book', sans-serif;
    font-weight: 600;
    letter-spacing: .5px;
}

.data-info {
    display: inline-block;
    font-size: 18px;
    line-height: 20px;
    font-family: 'FuturaBT-Book', sans-serif;
}

.verified-tag,
.unverified-tag {
    color: #60b266;
    border: 1px solid #60b266;
    padding: 3px 8px;
    border-radius: 20px;
    font-size: 14px;
    letter-spacing: .2px;
    font-weight: 600;
    line-height: 16px;
    width: fit-content;
    width: -moz-fit-content;
    cursor: pointer;
}

.unverified-tag {
    color: #de442f;
    border: 1px solid #de442f;
}

.profile-header button.edit-btn {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 20px;
    font-weight: normal;
    font-family: "FuturaBT-Book", sans-serif;
    cursor: pointer;
    padding: 5px;
    text-transform: none;
    color: #939596;
}

.profile-header button.edit-btn>span {
    margin-left: 8px;
}

.user-info-box {
    width: 100%;
    padding: 15px;
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 25px;
    box-sizing: border-box;
}

.user-info-box .info-wrapper {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.user-info-box .profile-wrapper,
.user-info-box .kyc-info,
.user-info-box .account-info,
.user-info-box .wallet-info {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
}

.user-info-box .profile-wrapper {
    margin-left: 0;
    margin-right: 5%;
}

.user-info-box .profile-wrapper>label {
    text-align: center;
    margin-top: 8px;
    font-size: 14px;
    color: #a7a7a7;
    cursor: pointer;
}

.user-info-box .profile-wrapper > img {
    border-radius: 50%;
    object-fit: cover;
}

.user-info-box .profile-wrapper .user-profile-input {
    display: none;
}

.f-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 25px;
}

.f-row:last-child {
    margin: 0;
}

.info-cell {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 0 10px;
    width: calc(100% / 5);
    margin-bottom: 10px;
}

.info-cell:first-child {
    padding-left: 0;
}

.info-card {
    display: inline-block;
    margin-bottom: 25px;
    background-color: #ffffff;
    border-radius: 8px;
    width: 100%;
}

.info-card .card-header {
    width: 100%;
    padding: 15px 30px;
    border-bottom: 1px solid #eaeaea;
    box-sizing: border-box;
}

.info-card .card-header>h3 {
    font-size: 20px;
    line-height: 22px;
    font-family: 'FuturaBT-Medium', sans-serif;
    color: #60b266;
    letter-spacing: .4px;
    text-transform: capitalize;
    margin: 0;
}

.info-card .card-body {
    width: 100%;
    padding: 15px 30px;
    box-sizing: border-box;
}

.contact-info.info-card .info-cell {
    width: calc(100% / 3);
}

/* Profile Edit Start ***/

.info-cell-edit {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 10px;
    box-sizing: border-box;
}
.info-card.bank-details .info-cell-edit {
    flex: 50%;
    margin-bottom: 20px;
}
.info-cell-edit .MuiOutlinedInput-root input {
    padding: 12px;
    height: 50px;
    border: 1px solid #d6d9d9;
    border-radius: 5px;
    box-sizing: border-box;
}
.info-cell-edit .MuiOutlinedInput-root.Mui-disabled input {
    background-color: #f1f5f6;
}
.info-cell-edit .MuiOutlinedInput-root fieldset {
    border: none !important;
}
.card-header.edit-profile > h3 {
    padding: 0 10px;
}
.edit-user-profile .form-button {
    height: auto;
    padding: 10px 15px;
    font-size: 16px;
    line-height: 20px;
    font-family: 'FuturaBT-Book',sans-serif;
    font-weight: 600;
    color: #60b266;
    border: 1px solid #60b266;
    background-color: transparent;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: .5px;
    margin-top: 0;
}
.edit-user-profile .form-button:hover{
    box-shadow: 0 0 3px #60b266;
}

@media only screen and (max-width: 1280px) {
    .profile-bg-main {
        padding: 40px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .info-card .info-cell {
        width: calc(100% / 3);
    }

.info-card .info-cell:nth-child(4n) {
    padding-left: 0;
}
}

@media only screen and (max-width: 767px) {
    .profile-bg-main {
        padding: 20px;
        margin: 0;
}

@media screen and (max-width: 480px) {
    .profile-bg-main {
        padding: 20px 15px;
    }
}

.info-card .info-cell,
.contact-info.info-card .info-cell {
    width: calc(100% / 2);
    margin-bottom: 20px;
}

.info-card .info-cell:nth-child(3n) {
    padding-left: 0;
}

.bank-details.info-card .info-cell:last-child {
    padding-left: 0;
}

.user-info-box .info-wrapper {
    width: 100%;
}

.user-info-box .profile-wrapper,
.user-info-box .kyc-info,
.user-info-box .account-info,
.user-info-box .wallet-info {
    margin: 0;
    flex: 30%;
    padding: 0 5px;
    margin-bottom: 20px;
}

.user-info-box .account-info,
.user-info-box .wallet-info {
    flex: 50%;
}

.info-card .card-header,
.info-card .card-body {
    padding: 15px 20px;
}

.data-label, .data-info {
    word-wrap: break-word;
}

.data-info.Bword {
    word-break: break-all;
}

.user-info-box .profile-wrapper > label {
    text-align: left;
    padding-left: 12px;
}

.profile-header .profile-title {
    font-size: 24px;
    line-height: 26px;
}

.info-card .info-cell-edit {
    flex: 100% !important;
    margin-bottom: 20px !important;
    padding: 0 !important;
}

.card-header.edit-profile > h3 {
    padding: 0;
}

.edit-user-profile .info-card .f-row {
    margin-bottom: 0;
}
}

@media only screen and (max-width: 400px) {
    .user-info-box .profile-wrapper,
    .user-info-box .kyc-info {
        flex: 22%;
    }

    .info-card .info-cell-edit {
        flex: 100% !important;
        margin-bottom: 20px !important;
        padding: 0 !important;
    }

    .card-header.edit-profile > h3 {
        padding: 0;
    }

    .edit-user-profile .info-card .f-row {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 400px) {
    .user-info-box .profile-wrapper,
    .user-info-box .kyc-info {
        flex: 22%;
    }
}