#HeaderMain .mobile-topnav {
    display: none;
}
.mobile-topnav .toggle-Button {
    margin: 0;
    padding: 10px;
}
.nav-link a{
    color: black;
    font-size: 20px;
    line-height: 22px;
    font-family: 'FuturaBT-Book',sans-serif;
    width: 100%;
}
.nav-link.MuiListItem-button {
    padding: 15px 20px;
}
.nav-link.MuiListItem-button.Mui-selected, 
.nav-link.MuiListItem-button.Mui-selected:hover {
    background-color: #c5f0d5;
    color: #5dac5d;
    border-radius: 8px 0 0 8px;
}
.drawer-container .drawer-header {
    min-height: 150px;
    min-width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(#ffffff, #e9f5f7);
}

.drawer-header .loggedin-user-info {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
}
.drawer-header .loggedin-user-info img {
    border-radius: 50%;
    object-fit: cover;
}
.drawer-header .loggedin-user-info .info {
    width: calc(100% - 45px);
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    word-break: break-all;
}
.loggedin-user-info .info span {
    font-size: 16px;
    line-height: 18px;
    font-family: 'FuturaBT-Medium',sans-serif;
}
.loggedin-user-info .info small {
    font-size: 14px;
    line-height: 16px;
    font-family: 'futuraBT-Book',sans-serif;
    margin-top: 3px;
}
#HeaderMain .desktop-topnav a {
    font-family: 'FuturaBT-MEdium',sans-serif !important;
}
#HeaderMain .desktop-topnav .register-btn-nv {
    font-family: 'FuturaBT-Book',sans-serif !important;
}

.nav-link.MuiListItem-button.Mui-selected a{
    color: #5dac5d;
    font-family: 'FuturaBT-Medium',sans-serif !important;
}

.drawer-header .MuiList-root {
    padding-left: 15px;
    box-sizing: border-box;
}

@media only screen and (max-width: 767px) {
    #HeaderMain .mobile-topnav {
        display: flex;
    }
    #HeaderMain .desktop-topnav {
        display: none;
    }
    #HeaderMain {
        padding-right: 0;
        box-sizing: border-box;
    }
}

@media only screen and (min-width: 768px) {
    .drawer-container {
        display: none !important;
    }
}