.profile-container-main {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: 100%;
}

.profile-bg-main {
    background: #f5fbfb;
    width: 100%;
    min-height: 100vh;
    border-top-left-radius: 18px;
    box-sizing: border-box;
    padding: 40px 160px 25px 70px;
}

.profile-bg-main .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.main-div-container {
    margin: '15px 0px 10px 0px';
    display: "flex";
    align-items: "center";
}

.your-balance-bg {
    background-color: 'white';
    border-radius: '10px'
}

.wallet-main .transection-box {
    background: #ffffff;
    padding: 15px;
    border-radius: 10px;
    box-sizing: border-box;
}

.wallet-main .page-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.wallet-main .page-title span {
    margin-left: 15px;
}

.wallet-main .record-box {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    border-bottom: 2px solid #ececec;
    font-size: 16px;
    line-height: 20px;
    font-family: 'FuturaBT-book',sans-serif;
}

.wallet-main .record-box:last-child {
    border-bottom: 0;
}

.wallet-main .record-box div:first-child{
    color: #878585;
    padding-right: 15px;
    box-sizing: border-box;
}

.wallet-main .transection-title {
    margin: 20px 0 15px 0;
    font-family: 'FuturaBT-Medium',sans-serif;
    font-size: 20px;
    line-height: 30px;
    color: #505253;
}

.record-box .credited {
    color: #00bc4a;
}

.record-box .debited {
    color: #dd5412;
}

.wallet-main .wallet-balance-box {
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    border:none;
    position: relative;
}
.wallet-main .wallet-balance-box img.contact_icon{position: absolute;
    right: 0;
    top: 0;
    min-height: 126px;
    width: 300px;
    max-height: 126px;
    object-fit: cover;
    border-radius: 0 10px 10px 0;}

.wallet-balance-box .box-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px;
    box-sizing: border-box;
    color: #6d6364;
}

.wallet-balance-box .box-data span {
    font-family: 'FuturaBT-Book', sans-serif;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 10px;
    display: inline-block;
    font-weight: 600;
}

.wallet-balance-box .box-data h4 {
    font-family: 'FuturaBT-Medium',sans-serif;
    font-size: 24px;
    line-height: 26px;
    margin: 0;
    font-weight: 600;
}

@media only screen and (max-width: 1280px) {
    .profile-bg-main {
        padding: 40px;
    }
}

@media only screen and (max-width: 767px) {
    .profile-bg-main {
        padding: 20px;
    }
    .wallet-main .record-box {
        padding: 15px;
    }
}

@media only screen and (max-width: 480px) {
    .wallet-balance-box .box-data {
        width: 75%;
    }
}
