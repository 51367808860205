.charter-main {
    margin-top: 50px;
}
.charter-main .page_title {
    font-family: 'FuturaBT-Book',sans-serif;
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
    text-decoration: underline;
    color: #494949;
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
}
.charter-main .page_title span {
    font-family: "FuturaBT-Medium",sans-serif;
    font-weight: 600;
    letter-spacing: .5px;
}

.charter-main .section-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}
.charter-main .section-container:last-child {
    margin-bottom: 0;
}
.charter-main .section-container .img-wrapper {
    max-width: 40%;
    width: 100%;
    text-align: center;
}
.section-container .data-wrapper {
    max-width: 60%;
    width: 100%;
    padding-left: 25px;
    box-sizing: border-box;
}
.section-container .data-head {
    font-size: 24px;
    line-height: 30px;
    font-family: "FuturaBT-Medium",sans-serif;
    letter-spacing: .5px;
    font-weight: 600;
    color: #4b4b4b;
}
.section-container .data-body {
    font-size: 18px;
    line-height: 26px;
    color: #4b4b4b;
    font-family: "FuturaBT-Medium",sans-serif;
    letter-spacing: .5px;
    margin-bottom: 0;
    margin-top: 10px;
}
.section-container.reverce .data-wrapper {
    padding-left: 0;
    padding-right: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.section-container.reverce .data-wrapper .data-head,
.section-container.reverce .data-wrapper .data-body {
    text-align: right;
}

.section-container .updated-date {
    font-size: 16px;
    line-height: 18px;
    font-family: "FuturaBT-Medium", sans-serif;
    letter-spacing: 0.5px;
    width: 100%;
    text-align: right;
    color: #4b4b4b;
}


@media only screen and (max-width: 959px) {
    .charter-main .section-container {
        flex-direction: column;
    }
    .charter-main .section-container.reverce {
        flex-direction: column-reverse !important;
    }
    .section-container .data-wrapper {
        padding: 0 !important;
        margin-top: 25px;
        max-width: 100%;
    }
    .charter-main .section-container .img-wrapper{
        max-width: 100% !important;
        text-align: left;
    }
    .charter-main .section-container.reverce .img-wrapper {
        text-align: right;
    }
    .charter-main .section-container .data-wrapper {
        max-width: 100% !important;
    }
}

@media only screen and (max-width: 767px) {
    .charter-main .section-container .img-wrapper,
    .charter-main .section-container.reverce .img-wrapper {
        text-align: center;
    }
    .section-container .data-head {
        font-size: 20px;
        line-height: 26px;
    }
    .section-container .data-body {
        font-size: 16px;
        line-height: 22px;
    }
    .charter-main .page_title {
        font-size: 22px;
        line-height: 26px;
    }
}

@media only screen and (max-width: 480px) {
    .charter-img {
        width: 100%;
    }
}