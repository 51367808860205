.MuiContainer-root.ekyc-container {
    max-width: 100%;
    height: 814px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #F5FBFB;
    margin: 0px;
}

.ekyc-container .ekyc-form-box {
    width: 534px;
    height: auto;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #474747;
    border-radius: 4px;
    padding: 30px;
    box-sizing: border-box;
}

.ekyc-container .ekyc-form-box.step-two {
    height: auto;
    box-sizing: border-box;
}

.ekyc-container .ekyc-form-box.step-three {
    justify-content: space-around;
    box-sizing: border-box;
}

.ekyc-container .stpper-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    margin-bottom: 32px;
}

.ekyc-container .stpper-wrapper .step-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Futura Bk BT", sans-serif;
}

.stpper-wrapper .step-box .step-num {
    font-family: "Futura Bk Bt", sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    box-sizing: border-box;
    border-radius: 50%;
    background-color: #d1d1d1;
    color: #ffffff;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stpper-wrapper .step-box.active .step-num {
    background-color: #69be4e;
}

.stpper-wrapper .step-box .step-info {
    font-family: "Futura Bk Bt", sans-serif;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    margin-top: 8px;
    color: #bcbdbc;
}

.stpper-wrapper .step-box.active .step-info {
    color: #959797;
}

.form-button {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00BC4A;
    border-radius: 4px;
    text-transform: none;
    font-family: "FuturaBT-Medium", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #FFFFFF;
    margin-top: 29px;
    cursor: pointer;
    border: none;
    letter-spacing: 1.5;
}

.ekyc-form-control {
    width: 100%;
    height: 56px;
    padding: 2px;
}

.ekyc-form-control input {
    height: 56px;
    box-sizing: border-box;
}

.ekyc-form-control input[name="panNumber"] {
    text-transform: uppercase;
}

.ekyc-form-control input[name="panNumber"]::placeholder,
.ekyc-form-control input[name="panNumber"]::-moz-placeholder {
    text-transform: none;
}

.ekyc-form-control .Mui-disabled {
    background-color: #f1f5f6;
}

.input-label {
    font-family: "FuturaBT-Medium", sans-serif !important;
    font-style: normal;
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #414141;
    margin-bottom: 9px;
    letter-spacing: .4px;
}

.ekyc-field .input-addtional-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 12px;
}

.input-addtional-info .actions {
    display: flex;
    flex-direction: column;
}

.ekyc-field .input-addtional-info span {
    font-size: 12px;
    letter-spacing: .4px;
    font-family: "FuturaBT-Book", sans-serif;
    font-style: normal;
}

.ekyc-field .input-addtional-info button {
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: 600;
    color: #69be4e;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 0;
}

.otp-fieldset {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.otp-fieldset input {
    border: 1px solid #d9e1e1;
    border-radius: 4px;
    height: 56px;
    width: 56px;
    padding: 5px;
    font-weight: inherit;
    -moz-appearance: textfield;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.otp-fieldset input::-webkit-outer-spin-button,
.otp-fieldset input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.filled-back-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.filled-back-info .back-btn {
    font-size: 14px;
    line-height: 16px;
    background-color: transparent;
    padding: 2px;
    border: none;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    cursor: pointer;
}

.filled-back-info .filled-data {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.filled-back-info .filled-data .info-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: calc(100% / 3);
    margin: 15px 0;
    font-family: "FuturaBT-Book", sans-serif;
}

.filled-data .info-box span {
    font-size: 14px;
    line-height: 16px;
    color: #8c8c8c;
    font-weight: normal;
    text-transform: capitalize;
}

.filled-data .info-box small {
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
    margin-top: 5px;
}

.ekyc-form-control .input-addon {
    height: auto !important;
    max-height: fit-content !important;
    max-height: -moz-fit-content !important;
    margin-right: 0;
}

.ekyc-field {
    margin-bottom: 20px;
}

.ekyc-field .validation-msg {
    font-family: "FuturaBT-Book", sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    color: #ff0000;
    margin-top: 8px;
}

.ekyc-container .page-title {
    max-width: 534px;
    width: 100%;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    font-family: "FuturaBT-Medium", sans-serif;
    margin-bottom: 30px;
}

.ekyc-field.passport-switch {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}
.passport-switch .label_text {
    width: calc(100% - 60px);
    padding-right: 10px;
    font-size: 16px;
    line-height: 18px;
    font-family: 'FuturaBT-Medium',sans-serif;
    cursor: pointer;
}
.passport-switch .switch-wrapper {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 22px;
}
.switch-wrapper input {
    visibility: hidden;
    opacity: 0;
    width: 100%;
    position: absolute;
    z-index: 9;
}
.switch-wrapper .switch {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 20px;
}
.switch:before {
    content: "";
    position: absolute;
    height: 16px;
    width: 16px;
    left: 5px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}
.switch-wrapper input:checked + .switch {
    background-color: #00BC4A;
}

.switch-wrapper input:focus + .switch {
    box-shadow: 0 0 1px #00BC4A;
}
.switch-wrapper input:checked + .switch:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
}

.skipn {
    text-align: center;
    padding-top: 10px;
    text-decoration: underline;
    color: #00bc4a;
    cursor: pointer;
}

.ekyc-otp-note {
    max-width: 534px;
    width: 100%;
    font-family: "FuturaBT-Medium", sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #414141;
    margin-top: 15px;
    text-align: justify;
}

@media only screen and (max-width: 767px) {
    .MuiContainer-root.ekyc-container {
        height: auto;
        padding: 40px 15px;
    }

    .ekyc-container .stpper-wrapper {
        width: 80%;
    }

    .ekyc-container .ekyc-form-box {
        width: 100%;
        height: auto;
        padding: 20px;
        box-sizing: border-box;
    }

    .input-addon {
        margin-right: -5px;
    }

    .otp-fieldset input {
        height: 40px;
        width: 40px;
    }
    .passport-switch .label_text {
        font-size: 14px;
    }
}

@media only screen and (max-width: 640px) {
    .filled-back-info .filled-data .info-box {
        width: 50%;
    }
}