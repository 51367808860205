.faq-main.MuiGrid-container {
    background-color: #f6fcfc;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 50px;
}
.faq-page-title {
    font-size: 26px;
    line-height: 30px;
    font-weight: 1000;
    font-family: 'FuturaBT-Medium',sans-serif;
    display: inline-block;
    margin: 60px 0 40px;
    width: 100%;
    text-align: center;
    letter-spacing: .5px;
}
.faq-buttons-section {
    margin-bottom: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.faq-buttons-section .button-wrapper {
    max-width: 400px;
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ecfcf4;
    border-radius: 30px;
    box-sizing: border-box; 
}
.faq-buttons-section .button-wrapper .tab-btn {
    flex: 1;
    min-height: 45px;
    font-size: 20px;
    line-height: 22px;
    padding: 10px;
    text-transform: capitalize;
    font-family: 'FuturaBT-Medium',sans-serif;
    border-radius: 30px;
    background-color: transparent;
    box-sizing: border-box;
    letter-spacing: .5px;
    transition: .3s;
}
.faq-buttons-section .button-wrapper .tab-btn.active {
    background-color: #69be4e;
    color: #ffffff;
}

.Faq-data-section {
    max-width: 950px;
    box-sizing: border-box;
    padding: 40px;
    border-radius: 25px;
    background-color: #ffffff;
}

.faq-accordion.MuiAccordion-root {
    box-shadow: none !important;
    border-bottom: 1px solid #9e9e9e;
}
.faq-accordion.MuiAccordion-root:last-child {
    border-bottom: none;
}
.faq-accordion.MuiAccordion-root::before {
    content: '';
    display: none;
}
.faq-accordion.MuiAccordion-root.Mui-expanded {
    margin: 0;
}

.faq-question.MuiAccordionSummary-root {
    min-height: 64px !important;
    padding: 20px 0;
    font-size: 20px;
    line-height: 22px;
    font-family: 'FuturaBT-Book',sans-serif;
    font-weight: 600;
    letter-spacing: .5px;
    box-sizing: border-box;
    word-break: break-word;
}
.faq-question.MuiAccordionSummary-root::after {
    content: '';
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
    background-image: url('../toggle-plus.png');
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    transition: .5s;
}
.faq-question.MuiAccordionSummary-root.Mui-expanded::after {
    content: '';
    background-image: url('../toggle-minus.png');
    transition: .5px;
}
.faq-question .MuiAccordionSummary-content {
    margin: 0 !important;
    padding-right: 10px;
    box-sizing: border-box;
}

.faq-answer.MuiAccordionDetails-root {
    padding: 0 0 20px;
    font-family: 'FuturaBT-Book',sans-serif;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: .5px;
    box-sizing: border-box;
    word-break: break-word;
}

.interactive-faqs-page #FooterMain {
    margin-top: 0 !important;
}

@media only screen and (max-width: 639px) {
    .faq-question.MuiAccordionSummary-root {
        font-size: 18px;
    }
    .faq-answer.MuiAccordionDetails-root {
        font-size: 16px;
        line-height: 20px;
    }
    .Faq-data-section {
        padding: 20px;
        margin: 0 15px;
    }
    .faq-main.MuiGrid-container {
        padding-bottom: 20px;
    }
    .faq-buttons-section .button-wrapper {
        margin: 0 15px;
    }
}