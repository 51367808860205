.referrals .page-title {
    font-size: 24px;
    line-height: 30px;
    font-family: "FuturaBT-Medium",sans-serif;
    font-weight: 600;
    color: #414141;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.referrals .page-title span {
    margin-left: 15px;
}

.referrals .referlink-section {
    display: flex;
    flex-wrap: wrap;
}

.referlink-section .link-input {
    width: calc(100% - 131px);
}

.referlink-section .link-input fieldset {
    display: none;
}

.referlink-section .link-input input {
    border: 1px solid #d6d9d9;
    background: rgba(229, 228, 226, .4);
    padding: 8px 15px;
    min-height: 45px;
    border-radius: 5px;
    font-family: 'FuturaBT-Book', sans-serif;
    box-sizing: border-box;
    width: 100%;
    color: #414141;
}

.referlink-section .copy-btn {
    text-transform: capitalize;
    font-weight: normal;
    font-family: 'FuturaBT-Medium',sans-serif;
    font-size: 18px;
    line-height: 20px;
    color: #fff;
    background-color: #69be4e;
    padding: 8px 15px;
    box-sizing: border-box;
    margin-left: 15px;
}

.referlink-section .copy-btn:hover {
    background-color: #69be4e;
}

.referlink-section .copy-btn.Mui-disabled {
    color: #fff;
    background-color: #69be4ecc;
}

.referlink-section .input-msg {
    display: inline-block;
    color: #6cc34d;
    font-family: 'FuturaBT-Book', sans-serif;
    font-weight: 600;
    letter-spacing: .3px;
    font-size: 16px;
    line-height: 20px;
    margin-top: 10px;
    width: 100%;
}

.referrals .referrals-guild {
    margin-top: 40px;
}

.referrals-guild .heading {
    font-family: 'FuturaBT-Medium', sans-serif;
    font-size: 20px;
    line-height: 22px;
    color: #474747;
}

.referrals-guild .guild-steps {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 50%;
    margin-top: 20px;
}

.guild-steps .step-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #929494;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    width: 30%;
}

.guild-steps .step-box span {
    text-align: center;
    margin-top: 8px;
}

.guild-steps .seperater {
    position: absolute;
    width: 50%;
    height: 2px;
    border-top: 2px dashed #6cc34d;
    box-sizing: border-box;
    top: 20px;
    left: 25%;
}

.guild-steps .seperater::before {
    content: '';
    height: 8px;
    width: 8px;
    background: #6cc34d;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: -5px;
    box-sizing: border-box;
}

.guild-steps .seperater::after {
    content: '';
    border: 6px solid transparent;
    border-left-color: #6cc34d;
    box-sizing: border-box;
    position: absolute;
    right: -6px;
    top: -7px;
}

.referrals .invite-btn {
    min-height: 42px;
    box-sizing: border-box;
    background: transparent;
    border: 1px solid #6cc34d;
    border-radius: 5px;
    font-family: 'FuturaBT-Medium', sans-serif;
    font-weight: 600;
    color: #6cc34d;
    text-transform: none;
    font-size: 16px;
    line-height: 20px;
    padding: 8px 15px;
    margin-top: 30px;
    display: flex;
}

.referrals .invite-btn img {
    margin-right: 10px;
}

.social-popover .MuiPopover-paper {
    padding: 10px;
    box-sizing: border-box;
}

.social-popover .social-button {
    margin: 0 5px;
}

@media only screen and (min-width: 1666px) {
    .referrals-guild .guild-steps {
        width: 45%;
    }
}

@media only screen and (max-width: 767px) {
    .referrals-guild .guild-steps {
        width: 100%;
    }
    .referrals .invite-btn {
        display: flex;
    }
}