.dashboard-header {
    padding: 15px 20px;
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    background: #ffffff;
    top: 0;
    z-index: 9;
}

.dashboard-header .user-info {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.dashboard-header .user-info .user-profile {
    border-radius: 50%;
    object-fit: cover;
    width: 35px;
    height: 35px;
}

.dashboard-header .user-info .username {
    font-family: "FuturaBT-Book", sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    margin: 0 5px;
}

.dashboard-header .user-info .arrow-img {
    transform: rotate(-90deg);
}

.dashboard-header .mobile-menu-btn {
    padding: 8px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: none;
    min-width: auto;
    border-radius: 50%;
}

.mobile-menu {
    display: none !important;
}

/**** sidebar design ****/
.sidebar-wrapper {
    max-width: 220px;
    min-width: 220px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    position: sticky;
    top: 80px;
    box-sizing: border-box;
}

.sidebar-wrapper .sidebar-link {
    font-family: "FuturaBT-Book", sans-serif;
    font-size: 18px;
    line-height: 20px;
    font-weight: normal;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    text-transform: capitalize;
    margin: 25px 0;
    padding: 8px;
    letter-spacing: 0;
    color: #adadad;
    box-sizing: border-box;
    margin-top: 0;
}

.sidebar-wrapper .sidebar-link:hover {
    text-decoration: none;
}

.sidebar-wrapper .sidebar-link span {
    padding-left: 10px;
}

.nav-link .sidebar-link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #5d5d5d;
}

.nav-link .sidebar-link:hover {
    text-decoration: none;
}

.nav-link .sidebar-link>span {
    padding-left: 10px;
}
.navlink-list.MuiList-padding {
    padding-left: 15px;
}
.navlink .nav-link {
    padding: 10px 15px;
}

.sidebar-wrapper .sidebar-link.active {
    color: #6cc356;
    font-family: 'FuturaBT-Medium',sans-serif !important;
}

@media only screen and (max-width: 767px) {
    .dashboard-header {
        padding: 15px;
    }

    .dashboard-header .user-info {
        display: none;
    }

    .mobile-menu-btn {
        display: inline-block;
    }

    .sidebar-wrapper {
        display: none !important;
    }

    .dashboard-header .mobile-menu-btn {
        display: inline-flex !important;
    }

    .mobile-menu {
        display: inline-block !important;
    }
}