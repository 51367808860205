.Confirm-signup-details-container {
    align-items: center;
    background-color: #F5FBFB;
    max-width: 100%;
}

.titleWhySignUp {
    margin-bottom: 32px;
    width: 700px;
    height: 56px;
    font-family: "FuturaBT-Book", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #6D6D6D;
}

.labelTitle {
    font-family: "FuturaBT", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 25px;
    text-align: center;
    margin-bottom: 32px;
    color: #474747;
    display: inline-block;
    letter-spacing: 1;
}

.input-label {
    font-family: "FuturaBT-Book", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #414141;
    margin-bottom: 9px;
}

.otpTextStyle {
    font-family: "Sans-serif";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #414141;
    margin-bottom: 9px;
    margin-top: 30px;
}

.sendOtpButtonStyle {
    height: 56px;
    background-color: #00BC4A;
    border-radius: 4px;
    text-transform: none;
    font-family: "sans-serif";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}

button.confirm-details-btn {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00BC4A;
    border-radius: 4px;
    text-transform: none;
    font-family: "FuturaBT-Boook", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #FFFFFF;
    margin-top: 29px;
    cursor: pointer;
}
button.confirm-details-btn:hover {
    background-color: #00BC4A;
}

.Confirm-details-box {
    box-sizing: border-box;
    width: 534px;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #474747;
    border-radius: 4px;
    padding: 30px;
    max-width: 100%;
}

.container-inner.MuiContainer-root {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #F5FBFB;
    margin: 100px 0;
}

.Confirm-details-box .input-form-control {
    width: 100%;
    min-height: 56px;
    padding: 2px;
    margin-bottom: 25px;
}

.input-form-control .MuiOutlinedInput-root.MuiInputBase-formControl,
.input-form-control .MuiInputBase-formControl > input {
    box-sizing: border-box;
    height: 56px;
}

.input-form-control input.Mui-disabled{
    background-color: #f1f5f6;
}

.input-form-control:last-child {
    margin-bottom: 0;
}

.validation-msg {
    font-family: "FuturaBT-Book", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #FF3B30;
    margin-top: 8px;
}

@media only screen and (max-width: 767px) {
    .labelTitle {
        font-size: 24px;
        line-height: 26px;
    }
}

@media only screen and (max-width: 639px) {
    .container-inner.MuiContainer-root {
        margin: 50px auto;
    }
    .Confirm-details-box {
        padding: 20px;
    }
}

@media only screen and (max-width: 479px) {
    .labelTitle {
        font-size: 20px;
    }
}