.status-box-guest-row-guest {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.status-box-guest-row-guest .status-box-guest {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background: #70c951;
    color: #fff;
    padding: 25px;
    width: calc(100% / 3 - 35px);
    height: 180px;
    box-sizing: border-box;
    border-radius: 8px;
}

.status-box-guest-row-guest .status-box-guest:nth-child(2) {
    margin: 0 15px;
    padding-bottom: 0;
}

.status-box-guest span {
    width: 100%;
    font-size: 32px;
    line-height: 26px;
    font-family: 'FuturaBT-Medium', sans-serif;
    font-weight: 600;
    margin-bottom: 10px;
    display: inline-block;
}

.status-box-guest small {
    width: 100%;
    font-size: 20px;
    line-height: 20px;
    font-family: 'FuturaBT-book', sans-serif;
    font-weight: normal;
    display: inline-block;
}

.status-box-guest:nth-child(2) span {
    margin-bottom: 0px
}

.status-box-guest:not(:nth-child(2)) img {
    margin-bottom: 10px;
}

.status-box-guest .graphimg {
    width: 100%;
    height: 50%;
}

.dashboard-page .dashboard-inner {
    margin-top: 50px;
}

.dashboard-page .dashboard-inner .page-title {
    display: flex;
    align-items: center;
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 25px;
}

.dashboard-inner .dashboard-search {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 25px;
}

.dashboard-search .search-input {
    width: 100%;
    border: 1px solid #d2d8d8;
    border-radius: 4px;
    margin-right: 15px;
}

.dashboard-search .search-input fieldset {
    display: none;
}

.dashboard-search .search-input input {
    width: 100%;
    min-height: 45px;
    font-size: 16px;
    line-height: 20px;
    padding: 8px;
    color: #525252;
    font-family: 'FuturaBT-Book', sans-serif;
    box-sizing: border-box;
}

.dashboard-search .search-input input::placeholder,
.dashboard-search .search-input input::-moz-placeholder {
    color: #d2d8d8;
}

.dashboard-search button,
.dashboard-search button:hover,
.dashboard-search button:active {
    padding: 10px 15px;
    min-height: 45px;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    background: #69be4f;
    font-family: 'FuturaBT-Medium', sans-serif;
    text-transform: capitalize;
}

.dashboard-inner .portfolio-cards-sections {
    flex-wrap: wrap;
    align-items: center;
}

.portfolio-cards-sections .portfolio-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    margin-right: 20px;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 12px;
}

.portfolio-card .card-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
    box-sizing: border-box;
}

.portfolio-card .card-row:last-child {
    padding-bottom: 8px;
    padding-top: 20px;
    border-top: 1px solid #ededed;
}

.portfolio-card .data-label {
    color: #a6a6a6;
    font-size: 16px;
    line-height: 20px;
    font-family: "FuturaBT-Book", sans-serif;
    font-weight: normal;
    margin-bottom: 10px;
    width: 100%;
}

.portfolio-card .label-info {
    width: 100%;
    font-size: 22px;
    line-height: 26px;
    color: #525252;
    font-family: 'FuturaBT-Medium', sans-serif;
    font-weight: 600;
}

.portfolio-card .date-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}

.portfolio-card .date-row .data-label {
    margin: 0;
    width: auto;
}

.portfolio-card .date-row .date {
    color: #a6a6a6;
    font-size: 16px;
    line-height: 20px;
    font-family: "FuturaBT-Book", sans-serif;
    font-weight: normal;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.portfolio-card .date-row .date img {
    margin-right: 5px;
}

.portfolio-card .document {
    font-size: 18px;
    line-height: 22px;
    font-family: 'FuturaBT-Book', sans-serif;
    font-weight: normal;
    padding: 8px;
    border-radius: 4px;
    background-color: #69be4f;
    color: #ffffff;
    margin-right: 8px;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
}

.potfolio-no-reports-main {
    width: 100%;
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 300;
}

.portfolio-no-reports-span {
    font-size: 25px;
}

.no-document-msg {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    color: #525252;
    font-family: 'FuturaBT-Medium', sans-serif;
    font-weight: 600;
    display: flex;
    align-items: center;
    flex-wrap: wrap; 
}

.no-document-msg img {
    cursor: pointer;
    margin-left: 8px;
}

@media only screen and (min-width: 1200px) {
    .portfolio-cards-sections .portfolio-card {
        width: calc(100% / 3 - 15px);
    }

    .portfolio-cards-sections .portfolio-card:nth-child(3n+3) {
        margin-right: 0;
    }
}

@media only screen and (max-width: 1366px) {
    .status-box-guest .graphimg {
        width: 100%;
    }

    .status-box-guest-row-guest .status-box-guest {
        width: calc(100% / 3 - 11px);
    }
}

@media only screen and (max-width: 1199px) {
    .portfolio-cards-sections .portfolio-card {
        width: calc(100% / 2 - 15px);
    }

    .portfolio-cards-sections .portfolio-card:nth-child(2n+2) {
        margin-right: 0;
    }

    .status-box-guest span {
        font-size: 22px;
        margin-bottom: 0;

    }

    .status-box-guest small {
        font-size: 16px;
    }

    .status-box-guest .graphimg {
        width: 100%;
        height: auto;
    }
}

@media only screen and (max-width: 1000px) and (min-width: 768px) {
    .portfolio-cards-sections .portfolio-card {
        width: 100%;
        margin: 0;
    }

    .status-box-guest .graphimg {
        width: 100%;
        height: auto;
    }

    .status-box-guest span {
        font-size: 14px;
        margin-bottom: 0;
    }
    .potfolio-no-reports-main {
        height: 250;
    }
    
    .portfolio-no-reports-span {
        font-size: 22px;
    }
}

@media only screen and (max-width: 992px) {

    .status-box-guest span {
        font-size: 14px;
    }

    .status-box-guest small {
        font-size: 16px;
    }

    .status-box-guest-row-guest .status-box-guest {
        height: 150px !important;
        min-height: 150px;
    }

    .status-box-guest-row-guest .status-box-guest:nth-child(even) {
        justify-content: unset !important;

    }

    .status-box-guest .graphimg {
        width: 100%;
        height: auto;
        margin-top: auto;
    }
    .potfolio-no-reports-main {
        height: 250;
    }
    
    .portfolio-no-reports-span {
        font-size: 22px;
    }
}

@media only screen and (max-width: 640px) {
    .portfolio-cards-sections .portfolio-card {
        width: 100%;
        margin: 0;
    }

    .status-box-guest-row-guest .status-box-guest {
        height: auto !important;
    }
    

    .status-box-guest-row-guest .status-box-guest {
        width: 100%;
        margin: 0 !important;
        margin-bottom: 20px !important;
    }

    .status-box-guest .graphimg {
        width: auto;
        height:auto;
    }

    .status-box-guest span {
        font-size: 14px;
        margin-bottom: 0;
    }
    .potfolio-no-reports-main {
        height: 150;
    }
    
    .portfolio-no-reports-span {
        font-size: 20px;
    }
}

