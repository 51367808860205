.tbl-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    border-radius: "8px";
    border: 1px solid #979797;
    border-bottom: 0px;
}
.loader {
    justify-content: center ;
    align-items: center;
    display: flex;
    height: 100vh;
    width: 100% !important;
    position: fixed;
    z-index: 99;
}
.tbl-row:first-child {
    border-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.tbl-row:last-child {
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom: 1px solid #979797;
}

.tbl-row span {
    display: flex;
    align-items: center;
    flex: 1;
    font-size: 18px;
    line-height: 22px;
    padding: 15px 10px;
    box-sizing: border-box;
}

.tbl-row span:first-child {
    border-right: 1px solid #979797;
}

.value-span {
    display: "flex";
    justify-content: "right";
    font-family: "FuturaBT-Book",sans-serif;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 1px;
    color: #3a3a3c;
}

.key-span {
    font-weight: 600;
    font-family: "FuturaBT-Book",sans-serif;
    font-size: 24px;
    letter-spacing: 1px;
    color: #3a3a3c;
}

.key-span>p {
    font-size: 14px;
    text-align: left;
}

.main-title {
    font-size: 34px;
    font-family: "FuturaBT-Medium",sans-serif;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #4a4a4a;
}

.page-title-wrapper,
.subtitle-wrapper {
    width: 100%;
    max-width: 760px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

.main-title-description {
    font-size: 20px;
    font-family: "FuturaBT-Book",sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
}

.term-checkbox.MuiCheckbox-root {
    padding-top: 0px;
}

.MuiCheckbox-colorPrimary.Mui-checked {
    color: #36bc58 !important;
}

.term-text {
    font-family: "FuturaBT-Book", sans-serif;
    font-size: 20px;
    line-height: 24px;
    color: #4a4a4a;
    padding-left: 0px;
    letter-spacing: 0.8px;
}

.term-text span {
    font-family: 'FuturaBT-book', sans-serif;
    font-weight: 600;
}

.payment-Btn.MuiButtonBase-root:disabled {
    opacity: 0.6;
    background-color: #36bc58;
}

.payment-Btn.MuiButtonBase-root {
    width: 80%;
    min-height: 50px;
    border-radius: 8px;
    border: none;
    background-color: #36bc58;
    color: #ffffff !important;
    font-size: 20px;
    line-height: 24px;
    padding: 10px 15px;
    font-family: "FuturaBT-Medium", sans-serif;
    box-sizing: border-box;
    text-transform: none;
}
.payment-Btn.MuiButtonBase-root:hover {
    background: #36bc58;
}

.make-payment {
    font-family: "FuturaBT-Medium",sans-serif;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: 28;
    color: #4a4a4a;
    margin: 30px;
}

button.Mui-selected {
    border: none !important;
}

.paymentpage #FooterMain {
    margin-top: 0px !important;
}

.amount-button {
    width: 110px;
    height: 56px;
    border-radius: 8px;
    border: 1px dashed #979797;
    background: #ffffff;
}

.paymentpage .payment-summary-table {
    border-radius: 8px;
    border: 1px solid #c6c6c6;
    background: #ffffff;
    margin-bottom: 50px;
    width: 100%;
    box-sizing: border-box;
}

.payment-main-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: auto;
    padding: 60px 0;
    background-color: #f1faf9;
    box-sizing: border-box;
}

.payment-details-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 40px 50px;
    max-width: 760px;
    background: #ffffff;
    border: 1px solid #97979730;
    box-sizing: border-box;
    border-radius: 24px;
}

.payment-details-block .stepper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.payment-details-block .stapper-tab {
    text-transform: none;
    font-size: 18px;
    line-height: 22px;
    font-family: "FuturaBT-Medium", sans-serif;
    font-weight: 600;
    min-width: auto;
    opacity: 0.7 !important;
}

.payment-details-block .stapper-tab.Mui-selected {
    opacity: 1 !important;
    border-bottom: 2px solid #080808 !important;
}

.wallet-balance-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    min-height: 60px;
    border-radius: 8px;
    border: 1px solid #757575;
    margin-bottom: 30px;
}

.wallet-select,
.wallet-balance {
    font-family: "FuturaBT-Book", sans-serif;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: #3a3a3c;
}

.wallet-select span.MuiCheckbox-root {
    padding: 0;
    margin-bottom: 2px;
    margin-right: 8px;
}

.wallet-select label {
    cursor: pointer;
}

.text-right {
    display: flex;
    justify-content: right;
}

.payment-info-tbl {
    width: 100%;
}

.payment-info-tbl.step1 {
    margin: 40px 0;
}

.accept-terms-block {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 80%;
    margin: 30px 0;
    cursor: pointer;
}

.investment-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 40px 0;
    width: 90%;
}

.investment-form .input-head {
    display: inline-block;
    width: 100%;
    font-size: 20px;
    line-height: 24px;
    color: #484848;
    font-family: 'FuturaBT-Medium',sans-serif;
    margin-bottom: 20px;
    text-align: center;
}

.investment-form  .payment-textfield {
    width: 100%;
}

.investment-form  .payment-textfield input {
    width: 100%;
    padding: 8px 15px;
    min-height: 50px;
    border: 1px solid #9b9b9b;
    border-radius: 5px;
    font-family: "FuturaBT-Medium",sans-serif;
    box-sizing: border-box;
}

.payment-textfield fieldset {
    display: none;
}

.payment-summary-container {
    max-width: 760px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


/*============== Modal css ==============*/

.Dialog {
    width: 100%;
}

.Dialog .MuiDialog-paper {
    position: relative;
    max-width: 400px;
    box-sizing: border-box;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 12px;
}

.Dialog .dialog-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.Dialog .dialog-inner>* {
    margin: auto;
    margin-bottom: 15px;
}

.Dialog .dialog-inner .dialog-title {
    font-size: 20px;
    line-height: 22px;
    font-family: 'FuturaBT-Medium', sans-serif;
    font-weight: 600;
    letter-spacing: 1;
    text-align: center;
}

.Dialog .dialog-inner .dialog-dis {
    font-size: 16px;
    line-height: 18px;
    font-family: 'FuturaBT-Book', sans-serif;
    font-weight: normal;
    text-align: center;
}

.Dialog .dialog-actions {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    width: 100%;
    margin-top: 10px;
}

.Dialog .dialog-actions button {
    padding: 12px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 600;
    font-family: 'FuturaBT-Medium', sans-serif;
    width: 100%;
    background-color: #68be5a;
    color: #ffffff;
    text-transform: none;
    margin-bottom: 12px;
}


span .PrivateSwitchBase-root-64 {
    padding-top: 0px;
}

.Dialog .dialog-actions button:last-child {
    margin-bottom: 0;
}

.MuiDialog-paperScrollPaper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Dialog.Ask-Ekyc .MuiDialog-paper {
    height: 295px;
    padding-left: 40px;
    padding-right: 40px;
}

.Dialog.Ask-signup .dialog-inner .dialog-title {
    line-height: 26px;
}

.Dialog .dialog-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    min-width: auto;
    cursor: pointer;
}

.payment-success-modal {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 30px;
    position: relative;
    max-width: 608px;
    width: 100%;
    height: 340px !important;
    border-radius: 24px;
}

.modal-icon {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.modal-icon-fail {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

@media only screen and (max-width: 767px) {
    .payment-details-block.MuiGrid-container {
        padding: 25px;
        width: 92%;
    }
    .investment-form {
        width: 100%;
    }
    .payment-Btn.MuiButtonBase-root {
        width: 90%;
    }
    .paymentpage .payment-summary-table {
        width: 92%;
    }
}

@media (min-width: 376px) and (max-width: 426px) {
    .tbl-row span {
        font-size: 18px;
        line-height: 22px;
    }

    .make-payment {
        font-size: 22px;
        line-height: 26px;
    }
}

@media only screen and (max-width: 426px) {
    .main-title {
        font-size: 24px;
        line-height: 28px;
    }

    .payment-Btn.MuiButtonBase-root {
        font-size: 18px;
        line-height: 22px;
    }

    .term-text {
        font-size: 16px;
        line-height: 20px;
    }
}

@media only screen and (max-width: 376px) {
    .tbl-row span {
        font-size: 12px;
        line-height: 16px;
    }

    .wallet-select,
    .wallet-balance {
        font-size: 16px;
        line-height: 20px;
    }

    .make-payment {
        font-size: 17px;
        line-height: 20px;
    }
}