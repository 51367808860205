@font-face {
  font-family: 'FuturaBT-Book';
  src: local('Futura'), url('../font/FuturaBT-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'Futura-Bold';
  src: local('Futura-Bold'), url('../font/unicode.futurabb.ttf') format('truetype');
}

@font-face {
  font-family: 'FuturaBT-Medium';
  src: local('FuturaBT-Book'), url('../font/Futura-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Futura-italic';
  src: local('Futura-Medium'), url('../font/FuturaBT-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Futura-light';
  src: local('Futura-light'), url('../font/FuturaBT-Light.ttf') format('truetype');
}